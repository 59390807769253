body,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
blockquote,
dl,
dt,
dd,
ul,
ol,
li,
pre,
fieldset,
lengend,
button,
input,
textarea,
th,
td,
div,
span,
view,
text,
input,
textarea {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    outline: none;
    line-height: 1;
}
